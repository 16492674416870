function createObject() {
  let example = {
    id: "LBST_Gruppen_flexible",
    showGroupNames: true,
    checkAfterHowManyItems: 3,
    checkAfterExactItemCount: true,
    workOrder:
      "Schauen Sie sich die Aufgaben an und überlegen Sie, ob Sie diese mündlich, halbschriftlich oder schriftlich lösen würden. Ziehen Sie je drei Beispiele in die passende Gruppe.",
    possibleCategories: [
      {
        id: 1,
        name: "mündlich",
      },
      {
        id: 2,
        name: "halbschriftlich",
      },
      {
        id: 3,
        name: "schriftlich",
      },
    ],
    elements: [
      {
        id: 6,
        image: require("@/assets/zr/Gruppen_flexibel/06.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 7,
        image: require("@/assets/zr/Gruppen_flexibel/07.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 1,
        image: require("@/assets/zr/Gruppen_flexibel/01.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/zr/Gruppen_flexibel/03.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/zr/Gruppen_flexibel/04.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/zr/Gruppen_flexibel/02.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 8,
        image: require("@/assets/zr/Gruppen_flexibel/08.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 5,
        image: require("@/assets/zr/Gruppen_flexibel/05.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 9,
        image: require("@/assets/zr/Gruppen_flexibel/09.png"),
        category: 3,
        flex: 3,
      },
    ],
  };

  const zweiRichtig =
    "Zwei Aufgaben hätten wir auch so eingeordnet. Eine hätten wir jedoch mit einem anderen Verfahren gelöst.";

  example.responses = [
    {
      id: "300",
      category: example.possibleCategories[0],
      text:
        "Genau. Diese Aufgaben bieten es an, dass man sie einfach mündlich addiert. Die Summen lassen sich schnell im Kopf berechnen, ohne dass Zwischenschritte oder Notationen nötig sind.",
    },
    {
      id: "030",
      category: example.possibleCategories[1],
      text:
        "Auch wir würden die Aufgaben so gruppieren. Entweder lässt sich das Ergebnis schnell durch ein stellenweises oder schrittweises Vorgehen ermitteln oder die Aufgaben bieten ein Vorgehen über eine Hilfsaufgabe oder die Strategie vereinfachen an.",
    },
    {
      id: "003",
      category: example.possibleCategories[2],
      text:
        "So hätten wir die Aufgaben auch eingeordnet. Die Aufgaben enthalten alle recht viele Stellenwertübergänge und es wird – zumindest nicht schnell auf den ersten Blick – eine Beziehung deutlich, die zum halbschriftlichen oder mündlichen Rechnen anregt.",
    },
    {
      id: "012",
      text: zweiRichtig,
    },
    {
      id: "102",
      text: zweiRichtig,
    },
    {
      id: "021",
      text: zweiRichtig,
    },
    {
      id: "120",
      text: zweiRichtig,
    },
    {
      id: "210",
      text: zweiRichtig,
    },
    {
      id: "201",
      text: zweiRichtig,
    },
    {
      id: "111",
      text:
        "Hier hätten wir anders gruppiert. Vielleicht hilft ein Blick darauf, ob sich schnell Zusammenhänge oder Beziehungen zwischen den Zahlen feststellen lassen oder eher nicht.",
    },
  ];

  return example;
}

export default createObject();
