<template>
  <div>
    <h2 class="faledia-caption">Flexibles Rechnen</h2>
    <p>
      Ein wesentliches Ziel des Mathematikunterrichts in der Primarstufe ist es,
      dass Kinder alle nötigen Voraussetzungen erhalten, flexibel rechnen zu
      können. Das beinhaltet zum einen „die Fähigkeit, über eine Vielfalt – oder
      zumindest mehrerer – möglicher Rechenwege zu verfügen“ (Greiler-Zauchner,
      2022, S. 23) und zum anderen „aus verschiedenen Rechenwegen den
      geeignetsten/adäquatesten Rechenweg zu wählen“ (ebd.). In anderen Worten
      heißt flexibel zu rechnen also, dass Kinder je nach Aufgabe entscheiden,
      ob sie die Lösung dazu mündlich, halbschriftlich oder schriftlich – also
      auf ihrem (individuellen) Weg und möglichst vorteilhaft – ermitteln (Fast,
      2017, S. 43).
    </p>
    <p>
      Die Grundlage dafür, flexibel rechnen zu können, bildet ein gesichertes
      Zahl- und Operationsverständnis. Das bedeutet, dass Kinder in der Lage
      sind, Beziehungen einerseits zu sehen und andererseits für ein
      vorteilhaftes Rechnen zu nutzen. Mit der Einführung der schriftlichen
      Algorithmen ab der dritten Schulstufe „verliert Zahlenrechnen […]
      keineswegs seine Berechtigung“ (Fast, 2017, S. 35). Allerdings gelten die
      schriftlichen Rechenverfahren als „Gefährder“ des flexiblen Rechnens, da
      sie durchaus dazu verleiten können, dass Kinder sie ständig nutzen, obwohl
      eine andere Methode vielleicht sinnvoller, zielführender oder schlicht
      einfacher wäre (Padberg & Benz 2021, S. 247). „Dies hängt mit dem Streben
      der Schüler nach möglichst großer Sicherheit zusammen“ (ebd.).
    </p>
    <p>
      Im Unterricht muss dem Rechnen auf eigenen Wegen und unter Ausnutzung
      eigener Präferenzen einerseits und geschickter Strategieanwendung
      andererseits Raum gegeben werden. Dafür ist das Einfordern von
      Begründungen im Sinne von „Warum hast du so gerechnet?“ oder auch den
      Rechenweg in Beziehung zu anderen Lösungswegen setzen „Wie hat deine
      Partnerin gerechnet und warum geht das auch?“, sowie der Austausch unter
      den Lernenden von zentraler Bedeutung (Rathgeb-Schnierer, 2011, S. 22).
      Ebenso wichtig ist, dass Kinder selbst entscheiden dürfen, auf welchem Weg
      sie rechnen (Padberg & Benz 2021, S. 248), solange kein starres Festhalten
      an einer einseitigen Anwendung (des schriftlichen Algorithmus) liegt.
    </p>
    <p>
      Grundsätzlich ist es also individuell, welche Rechenmethoden und
      Rechenstrategien Lernende verwenden. Doch gibt es Aufgaben, für die sich
      ein Vorgehen mehr oder weniger anbietet. Gruppieren Sie im nachfolgenden
      Beispiel, welche Aufgaben Sie tendenziell eher mündlich, welche
      halbschriftlich und welche schriftlich lösen würden. Beachten Sie aber
      dabei immer, dass hier nicht richtig oder falsch gilt – diese Übung dient
      lediglich dazu, Sie dazu anzuregen, Ihr eigenes Vorgehen zu reflektieren.
    </p>
    <LernbausteinGruppen
      :id="LBSTGruppen.id"
      :checkAfterHowManyItems="LBSTGruppen.checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen.checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen.showGroupNames"
      :workOrder="LBSTGruppen.workOrder"
      :possibleCategories="LBSTGruppen.possibleCategories"
      :elements="LBSTGruppen.elements"
      :responses="LBSTGruppen.responses"
      :showAlternative="false"
    >
    </LernbausteinGruppen>
    <p>
      Ob ein Kind die Strukturen sofort sieht, die sich anbieten oder doch
      anders vorgeht, gibt nicht zwangsläufig ein Bild davon ab, ob das Kind zum
      flexiblen Rechnen fähig ist oder nicht. Erst der konsequente Einsatz des
      schriftlichen Verfahrens bei jeder Art von Aufgabe lässt die Vermutung zu,
      dass ein Kind Schwierigkeiten damit haben könnte, Zahl- und
      Aufgabenbeziehungen wahrzunehmen und diese für das vorteilhafte Rechnen
      über Strategien zu nutzen. Auch hier gilt: Dies muss mit weiteren
      Diagnosen abgesichert werden.
    </p>
    <AppLiteraturZR />
    <AppBottomNavZR
      next="/ziffernrechnen/uebergang"
      back="/ziffernrechnen/hintergrundwissen/verstehensorientierte-erarbeitung"
    />
  </div>
</template>

<script>
import AppBottomNavZR from "@/common/AppBottomNavZR";
import AppLiteraturZR from "@/common/AppLiteraturZR";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LBST_Gruppen_flexibel from "@/components/faledia/seiten/ziffernrechnen/LBST/LBST_Gruppen_flexibel";

export default {
  components: {
    AppBottomNavZR,
    AppLiteraturZR,
    LernbausteinGruppen,
  },
  data: () => ({
    LBSTGruppen: LBST_Gruppen_flexibel,
  }),
};
</script>
